// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

.bg-soft-light {
    background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.25) !important;
}
